import React, { useEffect, useState } from 'react';
import NumberInput from '../../components/NumberInput/NumberInput';
import './Button.css'


export default function Button() {
  const [showNumberInput, setShowNumberInput] = useState<any>(false);
  const [showButtonSection, setShowButtonSection] = useState<any>(true);
// const showNumberInput=(e)=>{

// }
  // useEffect()

  return (
    <div>
      {showButtonSection?
      <div className='disclaimer-btn-wrapper' >
        <div className='btn-wrapper'>
        <button className='btn-primary' onClick={(e)=>{setShowNumberInput(true);setShowButtonSection(false)}}>Agree</button>
        </div>   
        <div className='btn-wrapper'>
        <button className='btn-secondary'>Decline</button>
        </div>  
      </div>:null}
        
        {showNumberInput?
          <NumberInput />:null
        }
    </div>
  )
}
