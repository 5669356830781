import React, { useEffect, useRef, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import WebViewer from "@pdftron/webviewer";
import "./Sign.css";
import ReasonPopup from "../ReasonPopup/ReasonPopup";
import { useSelector,useDispatch } from "react-redux";
import {setDoc} from "../../app/slice/pdfslice"
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formInfo, formstatus,getEnrollmentData,getFormDeliveryOption,notificationWithCustomUrl,saveToBlob, sendMessageOnSave, setEnrollmentData, updateEnrollmentFormStatus } from "../../apis/fileinfo";
import config from '../../env.json'

var hasfield:any = false;
var state:string = "start";
var bufferFromIframe:any = null;
var te:any = null;
var blobsign:any = null;
var reason:any='';
var deliveryModeLocal:any=[]
var recepientNameLocal="";
var formInfoLocal:any=null
var isSigned=false;
var isEnrollment=false;
var enrollmentData:any=null
function Sign() {
  const viewer:any = useRef(null);
  let fileName: any;
  const serializer = new XMLSerializer();
//   const [fileUrl, setFileUrl] = useState(
//     "https://testblobmedia.blob.core.windows.net/media/mirformfilled.pdf"
//   );
  
  const [instance, setInstance] = useState<any>(null);
  const fileInfoState=useSelector((state:any)=>state.login)
  const {doc}=useSelector((state:any)=>state.login)
  const formRequestId=useSelector((state:any)=>state.login.id)
  const recepientName=useSelector((state:any)=>state.login.recepientName)
  const formIdActual=useSelector((state:any)=>state.login.formId)
  const [fileUrl, setFileUrl] = useState(config.REACT_APP_SRFFROM+formRequestId+".pdf");
  const [signFile, setSignFile] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [reasondata, setReasondata] = useState("");
  const [formId,setFormId]=useState(0);
  const [deliveryMode,setDeliveryModes]=useState<any>([]);
  const [recepientNameState,setRecepientNameState]=useState('');
  const [formInfoState,setFormInfoState]=useState<any>(null)
  const dispatch=useDispatch()
  const navigate=useNavigate()

  
  // useEffect(()=>{
  //   if(formRequestId)
  //   console.log(formRequestId)
  //   setFormId(formRequestId)
    
  // },[formRequestId])
  useEffect(()=>{
    console.log(fileInfoState)
    // if(!inputMobileDigit.number){
    //   navigate(-1);
    // }
    if(formIdActual){
      formInfo(formIdActual,(res:any)=>{
        setFormInfoState(res);
        formInfoLocal=state;
        if(res.formDeliveries.length>0){
          setDeliveryModes(res.formDeliveries)
          deliveryModeLocal=res.formDeliveries;
        }
      
      })
    }
  },[formIdActual])

  const convertToXfdf = (changedAnnotation: any, action: any) => {
    let xfdfString = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields />`;
    if (action === "add") {
      xfdfString += `<add>${changedAnnotation}</add><modify /><delete />`;
    } else if (action === "modify") {
      xfdfString += `<add /><modify>${changedAnnotation}</modify><delete />`;
    } else if (action === "delete") {
      xfdfString += `<add /><modify /><delete>${changedAnnotation}</delete>`;
    }
    xfdfString += `</xfdf>`;
    return xfdfString;
  };

  // helper function to send annotation changes to WebSocket server
  const sendAnnotationChange = (annotation: any, action: any) => {
    console.log("adasdas");
    if (annotation.nodeType !== annotation.TEXT_NODE) {
      const annotationString = serializer.serializeToString(annotation);

      const annotations = convertToXfdf(annotationString, action);
      blobsign=new Blob([annotation]);
      console.log("annotation Data from UI ::" + annotationString + "\t");

      //setAnnotationData(annotations);

      //  getAnnotationData && getAnnotationData(annotations);
      // connection.send(JSON.stringify({
      //   documentId: DOCUMENT_ID,
      //   annotationId: annotation.getAttribute('name'),
      //   xfdfString: convertToXfdf(annotationString, action)
      // }));
    } else {
      console.log("No data for annotations");
    }
  };

  useEffect(() => {
    if(formRequestId){
    setFormId(formRequestId)
    if(recepientName)
    {
      recepientNameLocal=recepientName;
      setRecepientNameState(recepientName);
    }

        // getFormDeliveryOption(formRequestId,(res:any)=>{
    //   if(res)
    //   {
    //     // let t:any=[]
    //     // debugger
    //     // res.map((x:any)=>{
    //     //   t.push(x.FormDelivery_Typ.toLowerCase());
    //     // })

    //     setDeliveryModes(res)
    //     deliveryModeLocal=res;
        
    //     console.log(res)
        
    //   }
    //   else
    //   {
    //     setDeliveryModes([])
    //     deliveryModeLocal=[];
    //   }
    // })
    isEnrollment=window.localStorage.getItem("isEnrollment")==="true"
    enrollmentData=window.localStorage.getItem("enrollmentData")
    let fileUrllocal=""
    if(isEnrollment){
      if(enrollmentData){
        enrollmentData=JSON.parse(enrollmentData)
        fileUrllocal=enrollmentData.EnrollmentFormPdf_Url?enrollmentData.EnrollmentFormPdf_Url:"https://filefoldertest.blob.core.windows.net/testpdf/Azwithf.pdf"
      }else
      fileUrllocal="https://filefoldertest.blob.core.windows.net/testpdf/Azwithf.pdf"
      setFileUrl(fileUrllocal)
     
    }
     else{
      fileUrllocal=config.REACT_APP_SRFFROM+formRequestId+".pdf"
      setFileUrl(fileUrllocal)
     }

    if (instance) {
      if(doc)
      instance.loadDocument(doc);
      else
      instance.loadDocument(fileUrllocal);
    } else {

      WebViewer(
        {
          path: "/webviewerNew/lib",
          initialDoc:  fileUrllocal,
          // config:'/webviewerNew/scripts/localScript.js',
          fullAPI: true,
          // disabledElements: [
          //   "toolbarGroup-Shapes",
          //   "toolbarGroup-Edit",
          //   "toolbarGroup-Insert",
          //   "toolbarGroup-Annotate",
          //   "toolbarGroup-Forms",
          //   "notesPanel",
          //   "viewControlsButton",
          //   "selectToolButton",
          //   "toggleNotesButton",
          //   "searchButton",
          //   "freeTextToolGroupButton",
          //   "crossStampToolButton",
          //   "checkStampToolButton",
          //   "dotStampToolButton",
          //   "rubberStampToolGroupButton",
          //   "dateFreeTextToolButton",
          //   "eraserToolButton",
          //   "panToolButton",
          //   // "signatureToolGroupButton",
          //   // "viewControlsOverlay",
          //   "textSignaturePanelButton",
          //   "imageSignaturePanelButton"
          // ],
          licenseKey:
            "Optiks Solutions, Inc (p360.com):OEM:ZING Engagement Suite, BirdzAI::B+:AMS(20230818):BAA5A63D0447780AF360B13AC982527860615FC53EBB427B7C62159434401EFE14E4BEF5C7",
          //  fullAPI: true,
          //  disableVirtualDisplayMode: true,
        },
        viewer.current
      )
      .then(async (instance:any) => {
        setInstance(instance);

        //instance.disableElements(['header']);
        //instance.disableElements(["toolsHeader"]);

        const {
          documentViewer,
          annotationManager,
          Annotations,
          PDFNet,
          Tools,
          Math
        } = instance.Core;
        const { WidgetFlags } = Annotations;
        const signatureTool = documentViewer.getTool('AnnotationCreateSignature');
        let signatureAnnotaionCustom1:any=null
        let signatureAnnotaionCordinate:any=null
        await PDFNet.initialize();
        //instance.UI.openElements(['signaturePanel']);
       

        // var fdf = new PDFNet.FDFDoc.create(await (await response.blob()).arrayBuffer());
        // 
      //   instance.UI.VerificationOptions.addTrustedCertificates([
      //    '/entrust_class_3_intermediate.cer',
      //  ])
          // const response = await fetch('https://testblobmedia.blob.core.windows.net/media/adobe_aatl_eutl.fdf'); 
          // let t=(await (await response.blob()).arrayBuffer())
          // var fdf = new PDFNet.FDFDoc.createFromMemoryBuffer(t);
          // instance.UI.VerificationOptions.loadTrustList(t); 
          // const response2 = await fetch('https://testblobmedia.blob.core.windows.net/media/entrust_class_3_intermediate.cer'); 
          // let t2=(await (await response2.blob()).arrayBuffer())
          // instance.UI.VerificationOptions.addTrustedCertificates([t2])
           fetch('https://testblobmedia.blob.core.windows.net/media/adobe_aatl_eutl.fdf').then(function(certExchange) {
          certExchange.blob().then(function(blobCertExchange) {
          instance.UI.VerificationOptions.loadTrustList(blobCertExchange);
          instance.UI.VerificationOptions.addTrustedCertificates([
            'https://testblobmedia.blob.core.windows.net/media/entrust_class_3_intermediate.cer',
          ]);
          console.log('loaded certificates');
        });
      });
      if(recepientNameState.length>0)
      annotationManager.setCurrentUser(recepientNameState)
      if(recepientNameLocal.length>0)
      annotationManager.setCurrentUser(recepientNameLocal)
      let ip:any="";
      let location:any="";
        hasfield=false
        let ipAddress:any=""
        try {
          ipAddress= await axios.get('https://api.db-ip.com/v2/free/self');
        } catch (error) {
          console.log(error)
        }
      
      if(ipAddress.data){
        ip=ipAddress.data.ipAddress
        location=ipAddress.data.city+" "+ipAddress.data.stateProv+","+ipAddress.data.countryCode
      }
        async function main() {
          

          const doc = await PDFNet.PDFDoc.createFromURL(fileUrllocal);
          if(doc){
          doc.initSecurityHandler();
          doc.lock();}

          if (doc && !isEnrollment) {
            try {
              const formData:any = {
                formstatus: "opened",
                SignedURL: fileUrllocal,
                formRequestId: formRequestId,
              };
              // const res = await axios.post(
              //   `${process.env.REACT_APP_FORMSTATUS}`,
              //   formData
              // );
              // console.log(res);
              formstatus(formData)
              let tempdel:any=deliveryMode
              if(deliveryModeLocal.length>0)
                tempdel=  deliveryModeLocal        
              tempdel.findIndex((x:any)=>{
              if(x.FormDeliveryEvent_Typ=="Open")
                {
                if(x.FormDelivery_Typ=="SMS"){
                let msgData:any={
                  To:fileInfoState.fromNumber,
                  From:fileInfoState.number,
                  "Bot_response":{
                      "response":'Form is Opened'
                  } ,
                  Data:formInfoState?formInfoState:formInfoLocal
                }
                sendMessageOnSave(msgData)
              }
              else{
                notificationWithCustomUrl(x.FormDeliveryAction_Url,formInfoState?formInfoState:formInfoLocal,(res:any)=>{

                })
              }
            }
              })
            } catch (ex) {
              console.log(ex);
            }
            
           
            let signFieldName=window.localStorage.getItem('type')==="h"?"PDFTronApprovalSigGraphHcp":"PDFTronApprovalSigGraph";
            let type=window.localStorage.getItem('type')
            if(!hasfield){
              const approval_signature_field =
              await doc.createDigitalSignatureField(signFieldName);
              const widgetAnnotApproval =
              await PDFNet.SignatureWidget.createWithDigitalSignatureField(
                doc,
                new PDFNet.Rect(300, isEnrollment?(type=="p"?100:200):200, 200, 100),
                approval_signature_field
              );
            const pageCount = await doc.getPageCount();
          
            const page1 = await doc.getPage(isEnrollment?(type=="p"?pageCount-1:pageCount):pageCount);
            await page1.annotPushBack(widgetAnnotApproval);
            hasfield = true;
          }

          //const doc=await PDFNet.PDFDoc.createFromBuffer(b)
          
         
         
          }

          if(isEnrollment){
            let hcp=window.localStorage.getItem('type')==="h"
            console.log(hcp)
            if(hcp){
              let signFieldName="PDFTronApprovalSigGraphHcp"
              let type=window.localStorage.getItem('type')
              if(!hasfield){
                const approval_signature_field =
                await doc.createDigitalSignatureField(signFieldName);
                const widgetAnnotApproval =
                await PDFNet.SignatureWidget.createWithDigitalSignatureField(
                  doc,
                  new PDFNet.Rect(300, 900, 200, 100),
                  approval_signature_field
                );
              const pageCount = await doc.getPageCount();
              const page1 = await doc.getPage(pageCount);
              await page1.annotPushBack(widgetAnnotApproval);
              hasfield = true;
            }
          }
          }
          const docbuf = await doc.saveMemoryBuffer(
            PDFNet.SDFDoc.SaveOptions.e_remove_unused
          );
          //instance.loadDocument(docbuf)
          const blob = new Blob([docbuf], { type: "application/pdf" });
          instance.UI.loadDocument(blob);
        }
        // add your own license key as the second parameter, e.g. PDFNet.runWithCleanup(main, 'YOUR_LICENSE_KEY')
        
        if (!hasfield && state != "signature") 
        PDFNet.runWithCleanup(main);


        async function extractAnnotationSignature(annotation:any, docViewer:any) {
            debugger;
            // Create a new Canvas to draw the Annotation on
            const canvas = document.createElement("canvas");
            // Reference the annotation from the Document
            const pageMatrix = docViewer
              .getDocument()
              .getPageMatrix(annotation.PageNumber);
            // Set the height & width of the canvas to match the annotation
            canvas.height = annotation.Height;
            canvas.width = annotation.Width;
            const ctx:any = canvas.getContext("2d");
            // Translate the Annotation to the top Top Left Corner of the Canvas ie (0, 0)
            ctx.translate(-annotation.X, -annotation.Y);
            // Draw the Annotation onto the Canvas
            annotation.draw(ctx, pageMatrix);
            // Convert the Canvas to a Blob Object for Upload
            debugger;
            canvas.toBlob((b:any) => {
              b.arrayBuffer().then((buffer:any) => {
                te = buffer;
                setSignFile(buffer);
              });
            });
          
          }

          async function VerifyAllAndPrint() {
            const in_public_key_file_path='/entrust_class_3_intermediate.cer'
            const doc = await documentViewer.getDocument().getPDFDoc();
            doc.initSecurityHandler();
            doc.lock();
            console.log('==========');
            const opts = await PDFNet.VerificationOptions.create(PDFNet.VerificationOptions.SecurityLevel.e_compatibility_and_archiving);
      
            var fdf = await PDFNet.PDFDoc.createFromURL('/adobe_aatl_eutl.fdf');
          await  opts.loadTrustList(fdf); 
            // Add trust root to store of trusted certificates contained in VerificationOptions.
            // Use trust level corresponding to an identity trusted even for certification signatures.
            await opts.addTrustedCertificateFromURL(
              in_public_key_file_path,
              {},
              PDFNet.VerificationOptions.CertificateTrustFlag.e_default_trust + PDFNet.VerificationOptions.CertificateTrustFlag.e_certification_trust
            );
      
            // console.log(doc.verifySignedDigitalSignatures(opts))
            // Iterate over the signatures and verify all of them.
            const digsig_fitr = await doc.getDigitalSignatureFieldIteratorBegin();
            let verification_status = true;
            for (; await digsig_fitr.hasNext(); await digsig_fitr.next()) {
              const curr = await digsig_fitr.current();
              const result = await curr.verify(opts);
              if (await result.getVerificationStatus()) {
                console.log('Signature verified, objnum: ' + (await (await curr.getSDFObj()).getObjNum()));
              } else {
                console.log('Signature verification failed, objnum: ' + (await (await curr.getSDFObj()).getObjNum()));
                verification_status = false;
              }
      
              switch (await result.getDigestAlgorithm()) {
                case PDFNet.DigestAlgorithm.Type.e_SHA1:
                  console.log('Digest algorithm: SHA-1');
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA256:
                  console.log('Digest algorithm: SHA-256');
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA384:
                  console.log('Digest algorithm: SHA-384');
                  break;
                case PDFNet.DigestAlgorithm.Type.e_SHA512:
                  console.log('Digest algorithm: SHA-512');
                  break;
                case PDFNet.DigestAlgorithm.Type.e_RIPEMD160:
                  console.log('Digest algorithm: RIPEMD-160');
                  break;
                case PDFNet.DigestAlgorithm.Type.e_unknown_digest_algorithm:
                  console.log('Digest algorithm: unknown');
                  break;
              }
      
              console.log(
                'Detailed verification result: \n\t' +
                  (await result.getDocumentStatusAsString()) +
                  '\n\t' +
                  (await result.getDigestStatusAsString()) +
                  '\n\t' +
                  (await result.getTrustStatusAsString()) +
                  '\n\t' +
                  (await result.getPermissionsStatusAsString())
              );
      
              const changes = await result.getDisallowedChanges();
              for (let i = 0; i < changes.length; ++i) {
                const change = changes[i];
                console.log('\tDisallowed change: ' + (await change.getTypeAsString()) + ', objnum: ' + (await change.getObjNum()));
              }
      
              // Get and print all the detailed trust-related results, if they are available.
              if (await result.hasTrustVerificationResult()) {
                const trust_verification_result = await result.getTrustVerificationResult();
                console.log((await trust_verification_result.wasSuccessful()) ? 'Trust verified.' : 'Trust not verifiable.');
                console.log(await trust_verification_result.getResultString());
      
                const tmp_time_t = await trust_verification_result.getTimeOfTrustVerification();
                switch (await trust_verification_result.getTimeOfTrustVerificationEnum()) {
                  case PDFNet.VerificationOptions.TimeMode.e_current:
                    console.log('Trust verification attempted with respect to current time (as epoch time):' + tmp_time_t);
                    break;
                  case PDFNet.VerificationOptions.TimeMode.e_signing:
                    console.log('Trust verification attempted with respect to signing time (as epoch time): ' + tmp_time_t);
                    break;
                  case PDFNet.VerificationOptions.TimeMode.e_timestamp:
                    console.log('Trust verification attempted with respect to secure embedded timestamp (as epoch time): ' + tmp_time_t);
                    break;
                }
      
                const cert_path = await trust_verification_result.getCertPath();
                if (cert_path.length === 0) {
                  console.log('Could not print certificate path.');
                } else {
                  console.log('Certificate path:');
                  for (let i = 0; i < cert_path.length; i++) {
                    console.log('\tCertificate:');
                    const full_cert = cert_path[i];
                    console.log('\t\tIssuer names:');
                    const issuer_dn = await (await full_cert.getIssuerField()).getAllAttributesAndValues();
                    for (let j = 0; j < issuer_dn.length; j++) {
                      console.log('\t\t\t' + (await issuer_dn[j].getStringValue()));
                    }
                    console.log('\t\tSubject names:');
                    const subject_dn = await (await full_cert.getSubjectField()).getAllAttributesAndValues();
                    for (let j = 0; j < subject_dn.length; j++) {
                      console.log('\t\t\t' + (await subject_dn[j].getStringValue()));
                    }
                    console.log('\t\tExtensions:');
                    const extension_dn = await full_cert.getExtensions();
                    for (let j = 0; j < extension_dn.length; j++) {
                      console.log('\t\t\t' + (await extension_dn[j].toString()));
                    }
                  }
                }
              } else {
                console.log('No detailed trust verification result available.');
              }
      
              console.log('==========');
            }
      
            return verification_status;
          };
          async function save() {
            const doc = await documentViewer.getDocument().getPDFDoc();
            if(isSigned){
              let signFieldName=window.localStorage.getItem('type')==="h"?"PDFTronApprovalSigHcp":"PDFTronApprovalSig"
              const approval_signature_field =
                await doc.createDigitalSignatureField(signFieldName);
              const widgetAnnotApproval =
                await PDFNet.SignatureWidget.createWithDigitalSignatureField(
                  doc,
                  new PDFNet.Rect(0, 0, 0, 0),
                  approval_signature_field
                );
              const page1 = await doc.getPage(1);
              await page1.annotPushBack(widgetAnnotApproval);
              // const doc=await PDFNet.PDFDoc.createFromBuffer(arr);
  
              const found_approval_field = await doc.getField(
                signFieldName
              );
              
              const sigHandlerId = await doc.addStdSignatureHandlerFromURL(
                "/P360.pfx",
                "123456"
              );
              //  const sigHandlerId = await doc.addStdSignatureHandler('/pkcs7.p7b')
              const found_approval_signature_digsig_field =
                await PDFNet.DigitalSignatureField.createFromField(
                  found_approval_field
                );
                await found_approval_signature_digsig_field.setDocumentPermissions(PDFNet.DigitalSignatureField.DocumentPermissions.e_no_changes_allowed);
              // await  doc.saveCustomSignatureBuffer(te, found_approval_signature_digsig_field)
              // (OPTIONAL) Add an appearance to the signature field.
  
              //  const img = await PDFNet.Image.createFromMemory2(doc, te);
  
              // const approvalSignatureWidget = await PDFNet.SignatureWidget.createWithDigitalSignatureField(doc, await PDFNet.Rect.init(0,0, 0, 0), found_approval_signature_digsig_field);
              // // await approvalSignatureWidget.createSignatureAppearance(img);
              // const page1 = await doc.getPage(1);
  
              // page1.annotPushBack(approvalSignatureWidget);
  
              //Prepare the signature and signature handler for signing.
             // found_approval_signature_digsig_field.setDocumentPermissions(PDFNet.DigitalSignatureField.DocumentPermissions.e_no_changes_allowed);
             console.log('Adding field permissions.');
        const fields_to_lock:Array<string>  = ['PDFTronApprovalSig'];
             await found_approval_signature_digsig_field.setFieldPermissions(PDFNet.DigitalSignatureField.FieldPermissions.e_lock_all);
             await found_approval_signature_digsig_field.signOnNextSaveWithCustomHandler(
                sigHandlerId
              );
             await found_approval_signature_digsig_field.setLocation(
                location
              );
             await found_approval_signature_digsig_field.setReason(
                reason? reason:"Document approval"
              );
             await found_approval_signature_digsig_field.setContactInfo(
                "www.p360.com"
              );
              
            }
            
           
             // save to blob storage
             const buf = await doc.saveMemoryBuffer(
              PDFNet.SDFDoc.SaveOptions.e_remove_unused
            );

            state='signedFinal'
            instance.loadDocument(buf);

           
            const blob = new Blob([buf], { type: "application/pdf" });
            
            let srffile = new File([blob], "Signed_"+formRequestId+"_"+new Date().toISOString()+".pdf");
            
            console.log(typeof(buf))

            //SendformStatus
        
              
            // try {
            //   const formData = {
            //     "formstatus":"completed",
            //     "formRequestId":formId
            // }

            //   const res = await axios.post(
            //     `${process.env.REACT_APP_FORMSTATUS}`,
            //     formData
            //   );
            //   console.log("Form status",res);

            // } catch (ex) {
            //   console.log(ex);
            // }
              // try {
                
              const formData = new FormData();
                  formData.append('file',srffile);
                  formData.append('formId',formRequestId.toString());

                 saveToBlob(formData,(res:any)=>{
                  if(res){
                    let hcp=window.localStorage.getItem('type')==="h"
                    if(!isEnrollment){

                        //this is designed for out of office so To number will be Rep number and from number will be HCP/others
                      let tempdel:any=deliveryMode
                      if(deliveryModeLocal.length>0)
                          tempdel=  deliveryModeLocal        
                      tempdel.findIndex((x:any)=>{
                      if(x.FormDeliveryEvent_Typ=="Signature"|| x.FormDeliveryEvent_Typ=="Submit")
                      {
                          if(x.FormDelivery_Typ=="SMS"){
                            let msgData:any={
                              To:fileInfoState.fromNumber,
                              From:fileInfoState.number,
                              Bot_response:'Here is your signed form. '+res,
                              Url:res,
                              Data:formInfoState?formInfoState:formInfoLocal
                            }
                      sendMessageOnSave(msgData)
                      }
                      else{
                        notificationWithCustomUrl(x.FormDeliveryAction_Url,formInfoState?formInfoState:formInfoLocal,(res:any)=>{
                        })
                       }
                       }
                      })
                      formstatus({
                        formstatus: "completed",
                        SignedURL: res,
                        formRequestId: formRequestId
                    })
                    }
                    else{

                      updateEnrollmentFormStatus(enrollmentData.Id,
                        {
                          "enf_patient_sig_captured" : hcp?null:(isSigned?("Yes "+moment().format('MM/DD/YYYY')):"No"),
                          "enf_approver_sig_captured" : hcp && isSigned?("Yes "+moment().format('MM/DD/YYYY')):"No",
                          "enf_pdf_url" : res
                      }  
                      )
                      enrollmentData.EnrollmentFormPdf_Url=res;
                      enrollmentData.SignatureOfPatient=hcp?"Y":(isSigned?"Y":null); 
                      enrollmentData.HCPSignature=hcp && isSigned ? "Y":null
                      setEnrollmentData(enrollmentData)
  //                     if(isSigned && !hcp){
  //                       let msgData:any={
  //                         To:fileInfoState.fromNumber,
  //                         From:enrollmentData.HCP_Nbr,
  //                         "Bot_response":{
  //   "response":'Please review and sign the enrollment form for '+enrollmentData?.FullName+' https://p360zingsignaturedev99.azurewebsites.net/enrollment/'+formRequestId+"?type=h"
  // },
                          
  //                         Url:res,
  //                         Data:formInfoState?formInfoState:formInfoLocal
  //                       }
  //                       sendMessageOnSave(msgData)
  //                     }
                    }

                  
                    
                    setTimeout(() => {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.style.display = "none";
                      a.href = url;
                      // the filename you want
                      a.download = "signed_doc.pdf";
                      document.body.appendChild(a);
                      a.click();
                      debugger
                      let documentdoc:any=document

                      //documentdoc.getElementById('save').style.display='none'
                    
                   }, 4000);
                    //sendMessageOnSave()
                  }
                 })

              //   const res = await axios.post(
              //     `${process.env.REACT_APP_FORMSAPI}`+"savetoblob",
              //     formData
              //   );
              //   console.log(res);
              // } catch (ex) {
              //   console.log(ex);
              // }            
          }
          async function addSignDetails(){
            console.log(signatureAnnotaionCustom1)
            const signCanvas = document.createElement('canvas');
		// Reference the annotation from the Document
		const pageMatrix = documentViewer.getDocument().getPageMatrix(signatureAnnotaionCustom1.PageNumber);
  
    signCanvas.height = signatureAnnotaionCustom1.Height;
		signCanvas.width = signatureAnnotaionCustom1.Width;
    const annotationList:any = [];
		const ctx:any = signCanvas.getContext('2d');
		// Translate the signatureAnnotaionCustom1 to the top Top Left Corner of the Canvas ie (0, 0)
		ctx.translate(-signatureAnnotaionCustom1.X, -signatureAnnotaionCustom1.Y);
    //signatureAnnotaionCustom1.Color = new Annotations.Color(1, 215, 88)
		signatureAnnotaionCustom1.setX(signatureAnnotaionCordinate.x);
		signatureAnnotaionCustom1.setY(signatureAnnotaionCordinate.y);
    
		//signatureAnnotaionCustom1.resize(new Math.rect(signatureAnnotaionCordinate.x, signatureAnnotaionCordinate.y, signatureAnnotaionCordinate.x + 40, signatureAnnotaionCordinate.y + 40))
		signatureAnnotaionCustom1.draw(ctx, pageMatrix);
    signCanvas.toBlob((blob: any) => {
      debugger
			 createCustomAnnotation(blob, ["Signer Name:"+annotationManager.getCurrentUser(),'Signing Time:'+new Date().toISOString().substring(0,10)+ " "+new Date().toISOString().substring(11,19)+"Z(UTC)",'Signer IP:'+ip,'Signing Reason:'+reason], annotationList, signatureAnnotaionCustom1.Width, signatureAnnotaionCustom1.Height)
       flatSign(annotationList)
      });
 async function createCustomAnnotation(signImg:any, data: string[],  annotationList:any, width:any, height:any) {
      let annotManager=annotationManager;
     
  
      class DigitalSignatureAnnotation extends Annotations.CustomAnnotation {
        constructor() {
          super('digitalSignature'); // provide the custom XFDF element name
          this.Subject = 'DigitalSignature';
        }
        draw(ctx:any, pageMatrix:any) {
          // the setStyles function is a function on markup annotations that sets up
          // certain properties for us on the canvas for the annotation's stroke thickness.
          this.setStyles(ctx, pageMatrix);
        
          const helper = new Annotations.CanvasHelper(ctx);
          const textParams = {
            fillColor: new Annotations.Color(255, 0, 0),
            opacity: 1,
            borderStyle: 'solid',
            fontSize: 4,
          }
          // first we need to translate to the annotation's x/y coordinates so that it's
          // drawn in the correct location
          ctx.translate(this.X, this.Y);
          const img = new Image();
          img.onload = (event) => {
            ctx.drawImage(event.target, 0, 0, 50, 50)
          }
  
          img.src = URL.createObjectURL(signImg);
          
          helper.drawText('Signed By', width+2, 0, textParams);
          let y = helper.measureText('Signed By')['actualBoundingBoxAscent'];
          
          let x=width+2
          data.forEach(info => {
            helper.drawText(info, x,y, textParams);
          //console.log(helper.measureText(info))
            let textHeight = helper.measureText(info)['actualBoundingBoxAscent'];
            y = y + textHeight;
          })
  
        }
      }
  
      DigitalSignatureAnnotation.prototype.elementName = 'digitalSignature';
      const customSignAnnotation = new DigitalSignatureAnnotation()
      console.log(signatureAnnotaionCordinate)
      customSignAnnotation.Width = 300;
      customSignAnnotation.Height = 200;
      customSignAnnotation.setPageNumber(signatureAnnotaionCordinate.pageNumber)
      customSignAnnotation.setX(signatureAnnotaionCordinate.x);
      customSignAnnotation.setY(signatureAnnotaionCordinate.y);
      customSignAnnotation.Color = new Annotations.Color(255, 0, 0);
  
      // annotManager.deleteAnnotations(annotManager.getAnnotationsList(), { force: false, isUndoRedo: true });
      annotationList.push(customSignAnnotation);
      annotManager.addAnnotations(annotationList);
      annotManager.drawAnnotationsFromList(annotationList);

      

    }
          } 
          document.getElementById('reasonSave')?.addEventListener('click',()=>{
            if(!isEnrollment)
              addSignDetails();
            else{
              if(isSigned)
                addSignDetails();
              else{
                state = "signed";
                flatSign({});
              } 
            }
          })
          async function flatSign(annotationList:any){
    
            console.log('inside flatsign')
             state = "signature";
             const doc1 = documentViewer.getDocument()
            console.log(await annotationManager.getAnnotationsList())
            let config:any={
              widgets: false,
              annotList:await annotationManager.getAnnotationsList()
            }
            if(isEnrollment){
              config.widgets= true;
              config.fields=true;
            }
            const xfdfString = await annotationManager.exportAnnotations(config);
            const data = await doc1.getFileData({
              // saves the document with annotations in it
              xfdfString,
              flatten: isEnrollment?(isSigned?true:false):true,
              
            });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: "application/pdf" });
            instance.UI.loadDocument(blob);
            //debugger;
          }
          // document.getElementById("save")?.addEventListener("click", async () => {
          //               // setPdfstate("signature");
                        
          //             });
        documentViewer.addEventListener("documentLoaded", async () => {
          
          const doc = await documentViewer.getDocument().getPDFDoc();
          if (state == "signature") {
            //document.getElementById("reason-popup").style.display = "block";
            save();
            state = "signed";
          }
          // if(state=='detailed')
          // flatSign();
          if(state=='signedFinal')
          VerifyAllAndPrint()
          annotationManager.addEventListener(
            "annotationSelected",
            async (annotationList:any) => {
              debugger;
              annotationList.forEach((annotation:any) => {
                if (annotation.Subject === "Signature"){
                  debugger
                  
                  signatureAnnotaionCustom1=annotation;
                  signatureAnnotaionCordinate={
                    x:annotation.X,
                    y:annotation.Y,
                    pageNumber:annotation.PageNumber
                  }
                  extractAnnotationSignature(annotation, documentViewer);
                }
              });
            }
          );

          signatureTool.addEventListener('locationSelected', (pageCoordinates: any) => {
            //signatureAnnotaionCordinate = pageCoordinates;
            console.log(pageCoordinates)
          });
          
          annotationManager.on("annotationChanged", async (e:any) => {
            // If annotation change is from import, return
            if (e.imported) {
              return;
            }
            e.forEach((annotation:any) => {
              if (annotation.Subject === "Signature")
              {
                isSigned=true
                signatureAnnotaionCustom1=annotation;
                  signatureAnnotaionCordinate={
                    x:annotation.X,
                    y:annotation.Y,
                    pageNumber:annotation.PageNumber
                  }
                extractAnnotationSignature(annotation, documentViewer);
              }
            });
            const xfdfString =
              await annotationManager.exportAnnotationCommand();
            // Parse xfdfString to separate multiple annotation changes to individual annotation change
            const parser = new DOMParser();
            const commandData = parser.parseFromString(xfdfString, "text/xml");
            const addedAnnots = commandData.getElementsByTagName("add")[0];
            const modifiedAnnots =
              commandData.getElementsByTagName("modify")[0];
            const deletedAnnots = commandData.getElementsByTagName("delete")[0];

            // List of added annotations
            addedAnnots.childNodes.forEach((child) => {
              sendAnnotationChange(child, "add");
            });

            // // List of modified annotations
            modifiedAnnots.childNodes.forEach((child) => {
              sendAnnotationChange(child, "modify");
            });

            // // List of deleted annotations
            deletedAnnots.childNodes.forEach((child) => {
              sendAnnotationChange(child, "delete");
            });
          });
        });

        const getFieldNameAndValue = (field: any) => {
          // Do something with data
          const { name, value } = field;
          console.log(field)
          if(name=="FullName" || name=="PrintPatientName")
          { 
             field.setValue(enrollmentData?.FullName)
          }
          if(name=="HCPName")
            {
              field.setValue(enrollmentData?.HCPFull_Nm)
            }
          console.log(`Name: ${name}, Value: ${value}`);


          // Check children fields
          field.children.forEach(getFieldNameAndValue);
      }

      documentViewer.addEventListener('annotationsLoaded', () => {
          const fieldManager = annotationManager.getFieldManager();
          fieldManager.forEachField(getFieldNameAndValue);
      });
        annotationManager.addEventListener('fieldChanged', (field: any, value: any) => {
            
          console.log(field.name)
          if(field.type=="Btn")
            value=value.toLowerCase()=='yes'?1:0

          if(field.name=="HCPName")
            enrollmentData.HCPFull_Nm=value
          enrollmentData[field.name]=value
          // if(enrollmentData){
          //   if(field.name=='benifitInvestigation')
          //   enrollmentData.BenefitInvestigation=value
          //   if(field.name=='priorAuthorization')
          //   enrollmentData.PriorAuthorization=value
          //   if(field.name=='research')
          //     enrollmentData.Research=value
          //   if(field.name=='research')
          //     enrollmentData.followUp=value
          //   if(field.name=='claims')
          //     enrollmentData.followUp=value
          //   if(field.name=='pateintHomePhone')
          //     enrollmentData.PatientHomePhone=value
          //   if(field.name=='genderPatient')
          //     enrollmentData.PatientGender=value
          //   if(field.name=='patientMobileNumber')
          //     enrollmentData.PatientHomeMobile=value
          //   if(field.name=='patientEmailAddress')
          //     enrollmentData.PatientEmailAddress=value
          //   if(field.name=='patientStreetAddress')
          //     enrollmentData.StreetAddress=value
          //   if(field.name=='alternateContact1')
          //     enrollmentData.AlternateContact1=value
          //   if(field.name=='alternateContact2')
          //     enrollmentData.AlternateContact2=value


          //   if(field.name=='cityPatient')
          //     enrollmentData.PatientCity=value
          //   if(field.name=='relationshipToPatient1')
          //     enrollmentData.RelationshipToPatient1=value
          //   if(field.name=='relationshipToPatient2')
          //     enrollmentData.RelationshipToPatient2=value
          //   if(field.name=='statePatient')
          //     enrollmentData.PatientState=value


          //   if(field.name=='zipPatient')
          //     enrollmentData.PatientZip=value
          //   if(field.name=='alternateContactPhone1')
          //     enrollmentData.AlternateContact1=value
          //   if(field.name=='alternateContactPhone2')
          //     enrollmentData.AlternateContact2=value

            if(field.name=='PrimaryInsuranceProviderName1')
              enrollmentData.PrimaryInsuranceProviderName=value
            if(field.name=='SecondaryInsuranceProviderName1')
              enrollmentData.SecondaryInsuranceProviderName=value
            if(field.name=='PharmacyInsuranceProviderName1')
              enrollmentData.PharmacyInsuranceProviderName=value

            if(field.name=='PrimaryInsurancePhone1')
              enrollmentData.PrimaryInsurancePhone=value
            if(field.name=='SecondaryInsurancePhone1')
              enrollmentData.SecondaryInsurancePhone=value
            if(field.name=='PharmacyInsurancePhone1')
              enrollmentData.PharmacyInsurancePhone=value


            if(field.name=='PrimaryCardholderName1')
              enrollmentData.PrimaryCardholderName=value
            if(field.name=='SecondaryCardholderName1')
              enrollmentData.SecondaryCardholderName=value
            if(field.name=='PharmacyCardholderName1')
              enrollmentData.PharmacyCardholderName=value

            if(field.name=='PrimaryCardholderDOB1')
              enrollmentData.PrimaryCardholderDOB=value
            if(field.name=='SecondaryCardholderDOB1')
              enrollmentData.SecondaryCardholderDOB=value
            if(field.name=='PharmacyCardholderDOB1')
              enrollmentData.PharmacyCardholderDOB=value


            if(field.name=='PrimaryPolicyPatientID1')
              enrollmentData.PrimaryPolicyPatientID=value
            if(field.name=='SecondaryPolicyPatientID1')
              enrollmentData.SecondaryPolicyPatientID=value
            if(field.name=='PharmacyPolicyPatientID1')
              enrollmentData.PharmacyPolicyPatientID=value


            if(field.name=='PrimaryGroup1')
              enrollmentData.PrimaryGroup=value
            if(field.name=='SecondaryGroup1')
              enrollmentData.SecondaryGroup=value
            if(field.name=='PharmacyGroup1')
              enrollmentData.PharmacyGroup=value


            
            if(field.name=='PrimaryBINPCN1')
              enrollmentData.PrimaryBINPCN=value
            if(field.name=='SecondaryBINPCN1')
              enrollmentData.SecondaryBINPCN=value
            if(field.name=='PharmacyBIN_PCN1')
              enrollmentData.PharmacyBIN_PCN=value


            








            
          // }
          
          

      });
      });
    }
  }
  }, [formRequestId]);
  useEffect(()=>{
    if(recepientName)
    {
      recepientNameLocal=recepientName;
      setRecepientNameState(recepientName);
    }
  },[recepientName])
  return (
    <div className="file-wrapper">
      <ReasonPopup show={showPopup} handleClose={(e:any)=>{
        console.log(e)
        setShowPopup(false);
        if(e){
        setReasondata(e);
        reason=e;
        document.getElementById('reasonSave')?.click()
        }
        }}/>
      <button style={{visibility:'hidden'}} id="reasonSave"></button>
      <div className="pdf-header">
        
        <span className='file-name'>Form</span>
        <button className='pdf-save-btn' id="save" data-testid='savebutton' onClick={()=>{ 
          if(isSigned){
          setShowPopup(true)}
          else{
            document.getElementById('reasonSave')?.click()
          }
          }}><SaveIcon /> Save</button>
      </div>
      <div
          className="webviewer"
          style={{ height: "100%" }}
          ref={viewer}
        ></div>
        
    </div>
  );
}

export default Sign;


