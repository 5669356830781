import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import './ReasonPopup.css'

const optionsList = [
    {
        "label":"I have reviewed this document"
    },
    {
        "label":"I approve this document"
    },
    {
        "label":"Sample Ordering"
    }
    
]
 /* istanbul ignore next */
export default function ReasonPopup({show, handleClose}:any) {
     const [value,setValue]=useState('I have reviewed this document')
    const [acValue, setACValue] = useState<any>(optionsList[0]);
  return (
    <Modal
        // hideBackdrop
        open={show}
        onClose={()=>handleClose(null)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box>
            <div className="reason-wrapper" id="reason-modal-wrapper">
            <div 
                className='close-icon'
                onClick={()=>handleClose(null)}
            >
                 <CloseIcon />            
          </div>
            <div className='reason-modal-body'>
                <h2 className='reason-title'>Reason for Signature</h2>
                <div className='autocomplete-wrapper'>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={optionsList}
                        disableClearable
                        sx={{ width: 300 }}
                        defaultValue={optionsList[0]}
                        onChange={(e,value)=>{debugger; setValue(value.label)}}
                        renderInput={(params) => <TextField {...params} label="Reason for Signature" />}
                 />
                </div>
                <div className='btn-reason-wrapper'>
                    <button className='otp-btn-primary' id="reasonSave"onClick={(e) => {handleClose(value) }}>Submit</button>
                  </div>
           
            </div>
            </div>
        </Box>
      </Modal>
  )
}

