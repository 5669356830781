import axios from 'axios'
import config from '../env.json';


export const fileInfo=async(id:any,callback:any)=>{
    try {
     await   axios.post(
            `${config.REACT_APP_FORMSAPI}`+"/formrequestotp/"+id,
          ).then((res:any)=>{
            if(res)
            callback(res.data);
            else
            callback(null)
          }).catch((err:any)=>callback(null))
          
    } catch (error) {
        callback(null)
    }

}
export const getFormInfo=async(id:any, type: any,callback:any)=>{  
    try {
      await   axios.post(
            `${config.REACT_APP_FORMSAPI}/enrollment/enrollmentrequestotp/${id}`,
            { type:type.toUpperCase() }
          ).then((res:any)=>{
            if(res)
            callback(res.data);
            else
            callback(null)
          }).catch((err:any)=>callback(null))
  //   callback({
  //     "OTP": 1300,
  //     "ToNumber": "3046358225",
  //     "Id": 1027,
  //     "FromNumber": "+13157583113",
  //     "RecepientName": "Mrunal Chauhan",
  //     "enrollmentId": "2ee3a386-6e77-41e4-b80f-c78f6434dcfa"
  // })
    } catch (error) {
        callback(null)
    }
}


export const getEnrollmentData=async(id:any,callback:any)=>{  
  try {
   await   axios.get(
          `${config.REACT_APP_FORMSAPI}/enrollment/forms?GUIDidentifier=${id}`
        ).then((res:any)=>{
          if(res){
            console.log(res)
  if(res?.data?.Data.length>0)
  callback(res?.data?.Data[0]);
   }
         
          else
          callback(null)
        }).catch((err:any)=>callback(null))
//   callback(
//     {
//     "Id": 1083,
//     "GUIDidentifier": "4db246f7-9c14-43c4-bce4-f005c39afc59",
//     "EmployeeID": 1000,
//     "PrescriberID": 4221,
//     "HCPID": 7384,
//     "FullName": "Mrunal Chauhan",
//     "DOB": null,
//     "PrimaryLanguage": null,
//     "StreetAddress": null,
//     "PatientCity": null,
//     "PatientState": null,
//     "PatientHomePhone": null,
//     "PatientHomeMobile": null,
//     "PatientEmailAddress": null,
//     "AlternateContact1": null,
//     "RelationshipToPatient1": null,
//     "AlternateContactPhone1": null,
//     "InsuranceProviderName": null,
//     "InsurancePhone": null,
//     "CardholderName": null,
//     "CardholderDOB": null,
//     "PolicyPatientID": null,
//     "Groupname": null,
//     "BIN_PCN": null,
//     "PrescriberName": null,
//     "Prescriber_Specialty": null,
//     "OfficeContactName": null,
//     "PrescriberPhone": null,
//     "PrescriberPhoneFax": null,
//     "PrescriberPhoneEmail": null,
//     "AlternateOfficeContactName": null,
//     "AlternatePrescriberContactPhone": null,
//     "AlternateContactEmail": null,
//     "PracticeName": null,
//     "PrescriberStreetAddress": null,
//     "PrescriberCity": null,
//     "PrescriberState": null,
//     "PrescriberZIP": null,
//     "PrescriberNPI": null,
//     "TaxID": null,
//     "PTAN": null,
//     "OtherProviderID": null,
//     "HCPName": null,
//     "HCPSignature": null,
//     "SignatureDate": null,
//     "BestDescribesYou": null,
//     "CommunicationPreference": null,
//     "PrintPatientName": null,
//     "SignatureOfPatient": null,
//     "PatientSignatureDate": null,
//     "EffectiveStart_DtTm": "2024-06-17T18:29:13.833Z",
//     "EffectiveEnd_DtTm": null,
//     "Active_Flg": "Y",
//     "CreatedBy_Id": 1000,
//     "CreatedBy_DtTm": "2024-06-17T18:29:13.833Z",
//     "UpdatedBy_Id": 1000,
//     "UpdatedBy_DtTm": "2024-06-17T18:29:13.833Z",
//     "PatientGender": null,
//     "BenefitInvestigation": null,
//     "PriorAuthorization": null,
//     "ClaimsAuthorizationAppealSupport": null,
//     "PatientZip": null,
//     "PrimaryInsuranceProviderName": null,
//     "PrimaryInsurancePhone": null,
//     "PrimaryCardholderName": null,
//     "PrimaryCardholderDOB": null,
//     "PrimaryPolicyPatientID": null,
//     "PrimaryGroup": null,
//     "PrimaryBINPCN": null,
//     "SecondaryInsuranceProviderName": null,
//     "SecondaryInsurancePhone": null,
//     "SecondaryCardholderName": null,
//     "SecondaryCardholderDOB": null,
//     "SecondaryPolicyPatientID": null,
//     "SecondaryGroup": null,
//     "SecondaryBINPCN": null,
//     "PharmacyInsuranceProviderName": null,
//     "PharmacyInsurancePhone": null,
//     "PharmacyCardholderName": null,
//     "PharmacyCardholderDOB": null,
//     "PharmacyPolicyPatientID": null,
//     "PharmacyGroup": null,
//     "PharmacyBIN_PCN": null,
//     "AlternateContact2": null,
//     "RelationshipToPatient2": null,
//     "AlternateContactPhone2": null,
//     "RelationshipToPatient": null,
//     "Research": null,
//     "FollowUp": null,
//     "EnrollmentFormPdf_Url": null,
//     "HCPFull_Nm": "Abigail Graham",
//     "HCP_Nbr": "+17322177924",
//     "Patient_Nbr": "+13046358225",
//     "EmployeeV_Nbr": "+13157583113"
// }
// )
  } catch (error) {
      callback(null)
  }
}


export const setEnrollmentData=async(formdata:any)=>{
  try {
    await   axios.put(
           `${config.REACT_APP_FORMSAPI}`+"/enrollment/forms",formdata
         ).then((res:any)=>{
           console.log(res)
         }).catch((err:any)=>console.log(err))
         
   } catch (error) {
      console.log(error)
   }
}
export const formstatus=async(formdata:any)=>{
  try {
   await   axios.post(
          `${config.REACT_APP_FORMSAPI}`+"/formRequeststatus",formdata
        ).then((res:any)=>{
          console.log(res)
        }).catch((err:any)=>console.log(err))
        
  } catch (error) {
     console.log(error)
  }

}


export const saveToBlob=async(formdata:any,callback:any)=>{
  try {
   await   axios.post(
          `${config.REACT_APP_FORMSAPI}`+"/savetoblob",formdata
        ).then((res:any)=>{
          console.log(res)
          callback(res.data)
        }).catch((err:any)=>callback(null))
        
  } catch (error) {
     console.log(error)
     callback(null)
  }
}


export const sendMessageOnSave=async (data:any) => {
    try{
      await  axios.post(
        `${config.REACT_APP_MESSAGE_API}`+"/oooAutoResponse",data
      ).then((res:any)=>{
        console.log(res)
      }).catch((err:any)=>console.log(err))
    }catch(e){
      console.log(e)
    }
}


export const getFormDeliveryOption=async(formId:any,callback:any)=>{
  try{
    await  axios.get(
      `${config.REACT_APP_FORMSAPI}`+"/formDeliveriesByRequestId/"+formId,
    ).then((res:any)=>{
    if(res){
      callback(res.data);
    }
    }).catch((err:any)=>callback(null))
  }catch(e){
    console.log(e)
    callback(null);
  }
}

export const notificationWithCustomUrl=async (url:any,params:any,callback:any) => {
  try{
    await  axios.post(
     url,params
    ).then((res:any)=>{
    if(res){
      callback(res.data);
    }
    }).catch((err:any)=>callback(null))
  }catch(e){
    console.log(e)
    callback(null);
  }
}

export const formInfo=async(id:any,callback:any)=>{

try{
  await  axios.get(
    config.REACT_APP_FORMSAPI+'/formscompletewithoutauth/'+id
  ).then((res:any)=>{
  if(res){
    callback(res.data);
  }
  }).catch((err:any)=>callback(null))
}catch(e){
  console.log(e)
  callback(null);
}
}
export const updateEnrollmentFormStatus=async(Id:any,data:any)=>{
  try{
    await  axios.put(
      config.REACT_APP_FORMSAPI+'/enrollment/updateEnfFormData/'+Id,data
    ).then((res:any)=>{
    if(res){
      console.log(res)
    }
    }).catch((err:any)=>console.log(err))
  }catch(e){
    console.log(e)
  }
}