import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fileInfo, getEnrollmentData, getFormInfo } from '../../apis/fileinfo';
import { setFromNumber, setNumber,setOtpValue, setPdfId, setRecepientName,setFormId } from '../../app/slice/pdfslice';
import VerificationModal from '../../components/Verification/VerificationModal'

import './NumberInput.css'

export default function NumberInput() {
  const dispatch=useDispatch()
  // const inputMobileDigit=useSelector((state:any)=>state.login)
  const [disableButton, setDisableButton] = useState(true);
  const [newMobileNumber, setNewMobileNumber] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [error,setError]=useState(false)
  useEffect(()=>{
    // console.log(inputMobileDigit)
    //dispatch(setNumber({number:newMobileNumber}))
  })
  /* istanbul ignore next */
  const getNewMobileNumber=(e:any)=>{ 
    setNewMobileNumber(e.target.value)
    if(e.target.value.length <4){
      setDisableButton(true)
    }else{
      setDisableButton(false)
     
    }
    // console.log(e.target.value.length == 4 && isNaN(e.target.value.length))
    // let isNumber = isNaN(e.target.value)
    // if(e.target.value.length == 4  )
    // setDisableButton(true)
    // console.log(window.location.pathname);
  }
  /* istanbul ignore next */
  const numberVarification=()=>{
    if(newMobileNumber.length <4){
      setDisableButton(true)
    }else{
      if(window.location.pathname.length>1){
        if(window.location.pathname.includes('enrollment')){          
          let id= window.location.pathname.substring(1).split('/')[1];
          const searchParams = new URLSearchParams(window.location.search);
          const typeValue :any= searchParams.get('type');
          
          dispatch(setPdfId(id))
          window.localStorage.setItem("isEnrollment","true")
          window.localStorage.setItem("type",typeValue)
          getFormInfo(id, typeValue,(res:any)=>{
            console.log('response ===', res)
            if(res){
              getEnrollmentData(id,(res:any)=>{
                window.localStorage.setItem("enrollmentData",JSON.stringify(res))
              })
              dispatch(setNumber(res.ToNumber))
              dispatch(setOtpValue(res.OTP))
              dispatch(setFromNumber(res.FromNumber))
              dispatch(setRecepientName(res.RecepientName?res.RecepientName:''))

              dispatch(setFormId(res.FormId))
              if(res.ToNumber.substring(res.ToNumber.length-4)==newMobileNumber){
                setDisableButton(false)
                setShowModal(true)
                setError(false)
              }
              else{
                setError(true)
                setDisableButton(true)
              }
            }
          })
        }    
        else
        {
          window.localStorage.setItem("isEnrollment","false")
          let id= window.location.pathname.substring(1).split('.')[0];
          dispatch(setPdfId(id))
          fileInfo(id,(res:any)=>{
        console.log(res)
        if(res){
          dispatch(setNumber(res.ToNumber))
          dispatch(setOtpValue(res.OTP))
          dispatch(setFromNumber(res.FromNumber))
          dispatch(setRecepientName(res.RecepientName?res.RecepientName:''))

          dispatch(setFormId(res.FormId))
          if(res.ToNumber.substring(res.ToNumber.length-4)==newMobileNumber){
            setDisableButton(false)
            setShowModal(true)
            setError(false)
          }
          else{
            setError(true)
            setDisableButton(true)
          }
        }
      })
        }
      }
    }
  }
  
  return (
    <div className='input-wrapper'>
      {showModal?
        <VerificationModal show={showModal} handleClose={()=>{setShowModal(false);}}/>:null}
        <div className='number-input-container'>
        <input className='mobile-number-input' data-testid="mobile-numberInput" maxLength={4} value={newMobileNumber} onChange={getNewMobileNumber} placeholder='Enter last 4 digit of your mobile number' required/>
        {error?<span className='error'>Please enter valid digits.</span>:null}
        </div>
        
        <div className='btn-wrapper'>
        <button className={disableButton?'btn-primary disable-btn':'btn-primary'}
        //  style={{ opacity: disableButton ? 1 : 0.4 }}
        disabled={disableButton}
         onClick={()=>{numberVarification()}}>Verify</button>
        </div>
    </div>
  )
}
